import React, { useEffect, useState } from 'react';
import '../index.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Dashboard = () => {

    let [user, setUser] = useState({});

    useEffect(() => {

        async function verifyToken() {
            try {
                let res = await fetch('/api/verify-token', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
        
                if(res.status === 401){
                    toast.error("Something went wrong")
                    window.location.href = '/login'
                }

                setUser(await res.json());
                toast.success("Welcome back!")
               } catch (error) {
                
                window.location.href = '/login'
               }
        }

        verifyToken();

      
    }, []);

    const generateCustomerPortal = async () => {

        try {
            let res = await fetch('/api/get-stripe-data', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            if(res.status === 401){
                //Maybe add a toast message here

                toast.error("Something went wrong")

                return
            }


            let data = await res.json();

            window.open(data.url, '_blank')
        } catch (error) {
            console.log(error);
            toast.error("Something went wrong")
        }
    }

    const getAddedToSlack = async () => {

        try {
            let res = await fetch('/api/get-added-to-slack', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            if(res.status === 401){
                toast("We couldn't find you in our system. Please contact Sean at sean@closingclientsgroup.com")
                return
            }
            
            let data = await res.json();

            if(data.addedToSlack){
                toast("You have been added to the Slack channel!")
            }
        } catch (error) {
            toast("We couldn't find you in our system. Please contact Sean at sean@closingclientsgroup.com")
        }
    }

   return (
    <>
        <Navbar />
        <div className='min-h-screen flex flex-col justify-center items-center gap-5'>
            <div className="card lg:card-side bg-base-100 shadow-xl">
            <figure>
                <img src="/ccgroup.png" style={{width: '200px'}} alt="Album"/>
            </figure>
            <div className="card-body">
                <h2 className="card-title">Welcome to the Dashboard!</h2>
                <p>You can manage your billing below.</p>
                <div className="card-actions justify-end">
                <button className='btn' onClick={generateCustomerPortal} type='button'>Manage Billing</button>
                </div>
            </div>
            </div>
        </div>
        
        <Footer />
        <ToastContainer position='bottom-right' />
    </>
);
};

export default Dashboard;